import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppWrapper from "./AppWrapper";

ReactDOM.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="902007570515-k41nej190o3prn1ud14b6ug0j1q8ae8g.apps.googleusercontent.com">
            <AppWrapper/>
        </GoogleOAuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);