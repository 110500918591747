import App from "./App";
import ProtectedPage from "./ProtectedPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function AppWrapper() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/protected" element={<ProtectedPage />} />
            </Routes>
        </Router>
    );
}

export default AppWrapper;