import React, { useState, useEffect } from 'react';
import './wordanim.css';

function WordAnimation({ words, interval = 2000 }) {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [transitionClass, setTransitionClass] = useState('fade-enter-active');

    useEffect(() => {
        const cycleWords = () => {
            setTransitionClass('fade-exit-active');
            setTimeout(() => {
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                setTransitionClass('fade-enter-active');
            }, 500);
        };

        const intervalId = setInterval(cycleWords, interval);
        return () => clearInterval(intervalId);
    }, [interval, words.length]);

    return (
        <div className={`animated-word ${transitionClass}`}>
            {words[currentWordIndex]}
        </div>
    );
}

export default WordAnimation;