import React, {useState} from 'react';

function ChatGPTForm() {
    const [prompt, setPrompt] = useState('Hva kan du hjelpe meg med?');
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await fetch(`http://localhost:8080/chatgpt?prompt=${encodeURIComponent(prompt)}`);
            const data = await res.json();
            setResponse(data.choices[0].message.content);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <h1>Hvordan kan jeg stå til tjeneste?</h1>
            <img src="/images/img.png" alt="Assistant AI"/>
            <form onSubmit={handleSubmit}>
                <label htmlFor="prompt">Skriv ditt spørsmål her:</label>
                <input
                    type="text"
                    id="prompt"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    required
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? (
                        'Vent...'
                    ) : (
                        'Send inn'
                    )}
                </button>
            </form>
            {response && (
                <div>
                    <h2>Svar:</h2>
                    <div className="response">{response}</div>
                </div>
            )}
        </div>
    );
}

export default ChatGPTForm;
