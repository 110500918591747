import ChatGPTForm from "./ChatGPTForm";

function ProtectedPage() {
    return (
        <div>
            <h3>Her kommer det <a href='https://github.com/vippsas/vipps-developers'>Vipps-integrasjon</a> for å betale for tjenestene og laste ned rapporter.</h3>
            <ChatGPTForm/>
        </div>
    );
}

export default ProtectedPage;